.intro {
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-top: 33px;
}

.intro img {
    width: 31px;
    margin-bottom: 167px;
}

.intro img:hover {
    cursor: pointer;
}