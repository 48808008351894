.aboutContainer {
    position: absolute;
    bottom: 13px;
    left: 13px;
    max-width: 60vw;
    margin-right: 13px;
}

.aboutContainer .row {
    display: flex;
    flex-direction: row;
}

.aboutContainer .row .heading {
    min-width: 142px;
}

@media only screen and (max-width: 768px) {
    .aboutContainer {
        /* margin: 18px; */
        width: auto;
        max-width: none;
    }
    .aboutContainer .row .heading {
        min-width: 122px;
    }
}