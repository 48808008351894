.project {
    /* height: 100vh; */
    min-height: fit-content;
    /* width: 82vw; */
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 10px;
    width: 60vw;
    max-width: 1024px;
    margin-bottom: 3vh; /* old 21vh */
}

.project .image {
    /* height: 58vh; */
    width: 100%;
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.project .image img {
    /* max-width: 100%;
    height: calc(60vw * 0.65); */
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: left bottom;
    pointer-events: none;
    user-select: none;
}

.project .image .progressiveImg {
    width: 100%;
    height: calc(60vw * 0.65);
    background-color: transparent;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.project .image .hidden {
    display: none;
}

.noBrg {
    background-color: transparent !important;
}

.removeBlur {
    animation: imageBlur 0.4s forwards;
}

@keyframes imageBlur {
    0% {
        filter: blur(39px);
    }
    100% {
        filter: blur(0px);
    }
}

.project .swiper-container {
    /* height: 60vw; */
}

.project .swiper-container .swiper-wrapper .swiper-slide {
    /* width: auto; */
  }
.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #e6e9e9;
}
/*   
.swiper-pagination-bullet {
    background-color: blue;
} */

.project .text {
    display: flex;
    flex-direction: column;
    /* min-height: 21vh; */
    /* width: 60vw; */
    margin-top: 4px;
    margin-bottom: 40px;
}

.project .text .row {
    display: flex;
    flex-direction: row;
}

.project .text .margin-right {
    margin-right: 80px;
    min-width: 100px;
}

.project .text .heading {
    /* font-size: 0.5625rem; */
    min-width: 142px;
}

.project .text .content {
    /* font-size: 0.875rem; */
    margin: 0px;
    line-height: 17px;
}
.project .text .content p {
    margin: 0px;
}

.project .text .text-vertical {
    display: flex;
    flex-direction: column;
    min-width: 160px;
}

@media only screen and (min-width: 1685px) {
    .project .image {
        height: 666px;
    }
    .project .image .progressiveImg {
        max-height: 100%;
    }
    .project .image img {
        max-height: 100%;
        /* max-height: 100%; */
    }
}

@media only screen and (min-width: 426px) and (orientation: portrait) {
    .project {
        height: 60vh;
    }
}

@media only screen and (max-width: 768px) {
    .project {
        padding: 0px 10px 30px 10px;
        width: auto;
        height: auto;
        min-height: 0px;
    }
    .project .image {
        height: 70vw;
    }
    .project .image .progressiveImg {
        height: 100%;
        /* justify-content: center; */
    }
    .project .image img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
    }
    .project .text {
        width: auto;
        height: auto;
    }
    .project .text .heading {
        min-width: 122px;
    }
}

@media only screen and (max-width: 768px) and (orientation: landscape) {
    .project {
        /* height: fit-content;
        min-height: 120vh; */
    }
    .project .image {
        height: calc(60vw * 0.65);
    }
    .project .image .progressiveImg {
        height: calc(60vw * 0.65);
    }
    .project .image img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
    }
}

@media only screen and (max-width: 507px) {
    .project .image {
        height: auto;
    }
}

@media only screen and (max-width: 425px) {
    .project {
        margin-bottom: 60px;
    }
    .project .text {
        flex-direction: column;
        height: auto;
    }
}