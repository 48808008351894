html {
    background-color: #F4F3F7;
}

#root {
    padding-top: 37px;
}

@font-face {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: bold;
    src: local('Helvetica Neue Bold'), local('Helvetica-Neue-Bold'), url(./fonts/HelveticaLTStd-Bold.otf) format('opentype'), /* url(./fonts/Roboto-Regular.woff) format('woff'); */
}

header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 8px 0px;
    position: fixed;
    top: 0;
    background-color: #F4F3F7;
}

header a {
    text-decoration: none;
    color: #000;
    font-size: 0.875rem;
}

header a:first-child {
    margin-left: 10px;
}

header a:last-child {
    margin-right: 10px;
}

header .linkHome {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.basetext {
    font-family: 'HelveticaNeue';
    font-weight: bold;
    font-size: 0.875rem;
    margin: 0px;
}

a {
    font-family: 'HelveticaNeue';
    font-weight: bold;
}

.basetext::selection,
a::selection {
    background-color: #e6e9e9;
}

.heading {
    /* font-size: 0.5625rem;
    margin-top: 0px;
    margin-bottom: 3px; */
}

.content {
    /* margin-top: 0px;
    margin-bottom: 19px;
    font-size: 0.875rem; */
}

a {
    text-decoration: none;
    color: #000;
}