.archive {
    padding: 10px;
    padding-top: 66px;
}

.archiveHeading {
    margin-bottom: 4px;
}

.imageRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.imageRow img {
    height: 107px;
}

.imageRow img:first-child, .imageRow img:not(:last-child)  {
    margin-right: 22px;
    margin-bottom: 22px;
}

@media only screen and (max-width: 768px) {
    .imageRow {
        /* justify-content: space-evenly; */
    }
}